<template>
    <div>

        <div class="page-title">色彩诊断</div>

        <color-plane type="color" @planeChanged="planeChanged">

            <a-tabs class="tabs" v-model="key" @change="tabChange">
                <a-tab-pane key="jx" tab="季型">

                    <div class="jx-list flex flex-left-center" v-if="seasons">
                        <template v-for=" (season,sk) in seasons ">
                            <div class="jx-item" :key="sk">
                                <svg width="225" height="487" viewBox="0 0 376 812" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <template v-for="(item,ik) in seasons[sk].svg ">
                                        <path @click="editJxColor(sk,ik)" :key="ik" :d="item.path" :fill="item.color"/>
                                    </template>
                                </svg>
                                <div class="jx-name">{{ season.name }}季型</div>
                            </div>
                        </template>
                    </div>

                </a-tab-pane>
                <template v-for="(item,ik) in rows">
                    <a-tab-pane :key="ik" :tab="item.title"></a-tab-pane>
                </template>
            </a-tabs>

            <template v-if=" key !== 'jx' ">
                <div class="bk-item" v-if=" colors && currentRow && currentRow.rows "
                     :style="{width:`${currentRow.rows.length * 160}px`}">
                    <div class="bk-list">
                        <template v-for="(row,rk) in currentRow.rows">
                            <div :key="rk" class="bk-row">
                                <div class="color-item-title">{{ row.name }}</div>

                                <template v-for="idx of currentRow.colorNum ">

                                    <div :key="idx" class="color-item"
                                         :set=" colorTmp =  colors[ currentRow.key][row.key][`a${idx - 1}`] "
                                         @click="editColor(currentRow.key,row.key,`a${idx - 1}`)"
                                    >

                                        <div v-if=" colorTmp && colorTmp.color "
                                             class="color-show"
                                             :style="{'backgroundColor':colorTmp.color} "></div>

                                    </div>

                                </template>


                            </div>
                        </template>
                    </div>
                </div>
            </template>

        </color-plane>


        <at-modal v-model="showColorModal" title="添加颜色" @ok="syncColor">
            <div class="at-input-group block">
                <div class="label">
                    <span>请输入RGB色号值</span>
                </div>
                <div class="label">
                    <at-input :max-length="7" :value="params.color" class="full-with" @input="colorChanged"/>
                </div>
                <div>
                    <div class="color-container">
                        <input :value="params.color" type="color" class="color-input" @input="colorChanged"/>
                        <div class="color-tips">点击选色</div>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </at-modal>

    </div>
</template>

<script>
import ColorPlane from "@/views/AST/ColorTools/Com/ColorPlane.vue";
import api from "@/repo/api";
import AtModal from "@/components/AtModal.vue";
import AtInput from "@/components/AtInput.vue";

export default {
    name: "Color",
    components: {AtInput, AtModal, ColorPlane},
    data() {
        return {
            key: 'jx',
            currentRow: [],
            rows: {
                md: {
                    title: "明度",
                    colorNum: 4,
                    rows: [
                        {
                            key: "high",
                            name: "高",
                        },
                        {
                            key: "highMiddle",
                            name: "中高",
                        },
                        {
                            key: "middle",
                            name: "中",
                        },
                        {
                            key: "lowMiddle",
                            name: "中低",
                        },
                        {
                            key: "low",
                            name: "低",
                        }
                    ]
                },
                cd: {
                    title: "纯度",
                    colorNum: 4,
                    rows: [
                        {
                            key: "high",
                            name: "高",
                        },
                        {
                            key: "highMiddle",
                            name: "中高",
                        },
                        {
                            key: "middle",
                            name: "中",
                        },
                        {
                            key: "lowMiddle",
                            name: "中低",
                        },
                        {
                            key: "low",
                            name: "低",
                        }
                    ]
                },
                ln: {
                    title: "冷暖",
                    colorNum: 5,
                    rows: [
                        {
                            key: "warm",
                            name: "暖",
                        },
                        {
                            key: "cold",
                            name: "冷",
                        }
                    ]
                }
            },
            colors: undefined,
            seasons: undefined,
            colorsTpl: {
                md: {
                    low: {},
                    lowMiddle: {},
                    middle: {},
                    highMiddle: {},
                    high: {},
                },
                ln: {
                    warm: {},
                    cold: {},
                },
                cd: {
                    low: {},
                    lowMiddle: {},
                    middle: {},
                    highMiddle: {},
                    high: {},
                }
            },
            colorItem: null,
            colorItemCopy: null,
            showColorModal: false,
            params: {
                path: "",
                color: "",
            },
            colorData: null,
        }
    },
    mounted() {
        this.tabChange()
    },
    methods: {
        editColor(ck, rk, idx) {

            if (this.planeId === 0) return;

            let item = this.colors[ck][rk][idx];

            this.params.path = `${ck}.${rk}.${idx}`
            this.params.type = 'colors'
            this.params.color = item.color

            this.showColorModal = true
        },
        editJxColor(sk, ik) {

            if (this.planeId === 0) return;


            let item = this.seasons[sk]['svg'][ik]

            this.params.path = `${sk}.svg.${ik}`
            this.params.type = 'seasons'
            this.params.color = item.color

            this.showColorModal = true
        },
        syncColor() {

            this.$loading.show()

            this.params.plane_id = this.planeId;

            api.post('/ast-color-plane/sync-test-color', this.params, data => {

                this.$loading.hide()
                this.$message.data(data)

                if (data.code !== 0) return;

                this.showColorModal = false

                this.getList()
            })
        },
        planeChanged(planeId) {
            this.planeId = planeId
            this.getList()
        },
        colorChanged(e) {
            this.params.color = e.currentTarget.value
        },
        getList() {

            this.$loading.show()

            api.get('/ast-color-plane/test-colors', {plane_id: this.planeId}, (data) => {

                this.$loading.hide()

                if (data.data) this.colorData = data.data

                this.loadData()
            })
        },
        loadData() {

            if (!this.colorData) {
                this.colors = JSON.parse(JSON.stringify(this.colorsTpl))
                return;
            }


            this.colors = this.colorData.colors
            this.seasons = this.colorData.season_colors

            this.$forceUpdate()
        },
        tabChange() {
            if (this.key === 'jx') return;
            this.currentRow = this.rows[this.key]
            this.currentRow['key'] = this.key
        }
    }
}
</script>

<style scoped lang="less">
.color-tips {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #0000006e;
    color: white;
    height: 40px;
    text-align: center;
    padding-top: 7px;
    pointer-events: none;
}

.bk-list {
    display: grid;

    justify-content: space-between;
    align-items: center;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-template-columns:repeat(auto-fit, minmax(20px, 1fr));
}

.color-show {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #ddd;
}

.color-item-title {
    text-align: center;
}

.bk-item {
    border: 1px solid #EEEEEE;
}

.bk-row {
    border-right: 1px solid #EEEEEE;

    &:last-child {
        border-right: 0;
    }
}

.color-item-title {
    border-bottom: 1px solid #EEEEEE;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.color-item {
    width: 72px;
    height: 72px;
    border-radius: 50%;

    background-image: url("/assets/icons/color-add-icon.png");
    background-size: contain;

    margin: 16px auto;
    cursor: pointer;
}

.color-container {
    flex-direction: column;
}

.color-input {
    position: relative;

    width: 130px;
    height: 130px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #eee;

    input {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        position: absolute;
        left: -10px;
        top: -10px;
    }
}

.tabs {
    border-top: 1px solid #dddddd;
}

.color-container {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    margin: 0 auto;

    input {
        width: 160px;
        height: 160px;
        left: -20px;
        top: -20px;
        position: absolute;
    }
}

.jx-item {
    position: relative;
}

.jx-name {
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 16px;
    text-align: center;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 20px;
    width: 100%;
}


</style>