<template>
    <div class="color-plane">
        <div class="plane">

            <button :class="{'waite-btn':activePlaneId !== 0 }" class="btn" @click="changePlane()">颜创通用色板</button>

            <template v-for=" (item,pk) in planes">

                <button :class="{'waite-btn':activePlaneId !== item.id }" class="btn ml-8" :key="pk"
                        @click="changePlane(item)">{{ item.name }}
                </button>

            </template>

            <button @click="activeAddModal" class="btn waite-btn edit-btn">添加色板</button>
        </div>
        <div class="plane-content" :class="{'plane-enabled': isChecked }">

            <div class="plane-enabled-img"></div>

            <div class="plane-switch flex flex-left-center">
                <span class="mr-4">色板启用</span>
                <a-switch :disabled=" activePlaneId === 0 && enabledPlaneId === 0 "
                          @change="planeEnable"
                          v-model="isChecked"/>
            </div>

            <div class="plane-items">

                <slot></slot>

            </div>
        </div>

        <at-modal v-model="showColorModal" title="添加色板" @ok="confirmColorPlane">

            <p>请输入色板名称</p>

            <at-input class="full-with" v-model="params.name"/>

        </at-modal>

    </div>
</template>

<script>
import AtModal from "@/components/AtModal.vue";
import AtInput from "@/components/AtInput.vue";
import api from "@/repo/api";

export default {
    name: "ColorPlane",
    components: {AtInput, AtModal},
    props: {
        type: String
    },
    data() {
        return {
            showColorModal: false,
            params: {
                name: ""
            },
            planes: [],
            activePlaneId: 0,
            enabledPlaneId: 0,
            isChecked: false,
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        changePlane(item) {
            this.activePlaneId = item ? item.id : 0
            this.isChecked = this.activePlaneId === this.enabledPlaneId

            this.$emit('planeChanged', this.activePlaneId)
        },
        activeAddModal() {
            this.params.name = ''
            this.showColorModal = true
        },
        planeEnable(e) {

            this.$loading.show()

            api.post('/ast-color-plane/plane-enable', {
                id: this.activePlaneId,
                status: e ? 1 : 0,
                type: this.type
            }, data => {

                this.$message.data(data)
                this.$loading.hide();

                if (data.code === 0) {

                    this.enabledPlaneId = e ? this.activePlaneId : 0

                }

            })
        },
        getList(disChange) {
            api.get('/ast-color-plane/list', {type: this.type}, data => {
                this.planes = data.data || []

                if (disChange) return;

                for (const pk in this.planes) {

                    let plane = this.planes[pk]

                    if (plane.enabled) {

                        this.enabledPlaneId = plane.id

                        return this.changePlane(plane)
                    }

                }

                this.changePlane()
            })
        },
        confirmColorPlane() {
            this.$loading.show()

            this.params.type = this.type

            api.post('/ast-color-plane/create-plane', this.params, (data) => {

                this.$loading.hide()

                this.$message.data(data)

                if (data.code === 0) {
                    this.showColorModal = false

                    this.activePlaneId = data.data.id
                    this.isChecked = this.activePlaneId === this.enabledPlaneId

                    this.getList(true)
                }

            })
        },
    }
}
</script>

<style scoped lang="less">
.plane-enabled {
    .plane-enabled-img {
        display: block;
    }

    .plane-switch {
        padding-left: 114px;
    }
}

.plane-enabled-img {
    width: 114px;
    height: 114px;
    background-image: url("/assets/icons/plan-enbale-icon.png");
    background-size: contain;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    z-index: 2;
    pointer-events: none;
}

.plane {
    padding: 16px 24px;
    border: 1px solid #EEEEEE;
}

.plane-content {
    padding: 24px;
    border: 1px solid #EEEEEE;
    border-top: 0;
    min-height: 300px;
    position: relative;
}

.plane-switch {
    font-size: 16px;
    color: #333333;
    line-height: 16px;
    transition: all .3s;
}

.waite-btn {
    border: 1px solid #EEEEEE;
    font-size: 14px;
    color: #999999;
    line-height: 16px;
    background-color: #FFFFFF;
    transition: all .3s;

    &:hover {
        color: #333333;
        border-color: #333333;
        border-radius: 4px;
    }
}

.edit-btn {
    float: right;
}

.plane-items {
    margin-top: 24px;
}
</style>